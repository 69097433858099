<template>
  <v-container
    v-if="$root.currentUser && $root.currentUser.isAdmin()"
    class="mt-12">
    <h1>Admin Page</h1>
    <v-row class="mt-3">
      <v-col v-for="app in apps" class="justify-center text-center" cols="6" md="4">
        <router-link v-if="app" :to="app.path">
          <v-card height="200" width="100%" class="mx-auto text-center align-center mb-2 shadow-small d-flex flex-column">
            <v-spacer></v-spacer>
            <span class="tit" style="color: rgba(37,38,94,.7)">{{ app.name }}</span>
            <v-spacer></v-spacer>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AdminPage",
  data() {
    return {
      admin: false,
      apps: [
        {
          name: 'Check Exams Status',
          path: '/upload/pdf',
          color: '#71b769',
        },
        {
          name: 'Images Uploader',
          path: '/upload/images',
          color: '#7469b7',
        },
        {
          name: 'PDFs Uploader',
          path: '/upload/pdfs',
          color: '#7469b7',
        },
        {
          name: 'Email Sender',
          path: '/email-sender',
          color: '#7469b7',
        },
      ]
    }
  },

}
</script>

<style scoped>

</style>
